import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import $ from "jquery";
import Element from "element-ui";
// import './plugins/element.js'
// 导入全局样式
import "./assets/css/global.css";
// 导入字体图标
import "./assets/fonts/iconfont.css";
// 导入表格树
import TreeTable from "vue-table-with-tree-grid";
import "element-ui/lib/theme-chalk/index.css";
// 导入富文本编辑器
import VueQuillEditor from "vue-quill-editor";
// 导入富文本编辑器样式
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
// 导入NProgress, 包对应的JS和CSS
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import md5 from "js-md5";

import axios from "axios";
import BaiduMap from "vue-baidu-map";
import VDistpicker from "v-distpicker";
import VueAMap from "vue-amap";

// 配置请求根路径
// 本机地址
axios.defaults.baseURL = "https://yjqx.yijianqixiang.com/api";
// axios.defaults.baseURL = 'https://www.jshkb.cn/api'
// axios.defaults.baseURL = "http://192.168.1.17:8002/api";
// axios.defaults.baseURL = 'http://120.55.60.66:8001/api/'
// 远程后台地址
// axios.defaults.baseURL = 'http://47.97.66.16:8001/'

// 在request 拦截器中, 展示进度条 NProgress.start()
// 请求在到达服务器之前，先会调用use中的这个回调函数来添加请求头信息
// axios.defaults.uploadURL = 'http://124.71.179.33:8002/api/upload/uploadFile'

Vue.prototype.$uploadURL = "https://yjqx.yijianqixiang.com/api/upload/uploadFile";
axios.interceptors.request.use(config => {
  NProgress.start();
  // console.log(config)
  // 为请求头对象，添加token验证的Authorization字段
  config.headers.Authorization = window.sessionStorage.getItem("token");
  // 在最后必须 return config
  return config;
});
// response 拦截器中,  隐藏进度条NProgress.done()
axios.interceptors.response.use(config => {
  NProgress.done();
  return config;
});

// 挂在到Vue实例，后面可通过this调用
Vue.prototype.$http = axios;
Vue.prototype.$md5 = md5;

Vue.config.productionTip = false;
// 组件全局注册 表格树
//省市区组件
Vue.component("v-distpicker", VDistpicker);
Vue.component("tree-table", TreeTable);
// 全局注册富文本编辑器
Vue.use(VueQuillEditor);
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  key: "c7f12fa6a29f26e6ff44c00fec96661e&callback=init",
  plugin: [
    "AMap.Autocomplete",
    "AMap.PlaceSearch",
    "AMap.Scale",
    "AMap.OverView",
    "AMap.ToolBar",
    "AMap.MapType",
    "AMap.PolyEditor",
    "AMap.CircleEditor",
    "AMap.Geocoder",
    "AMap.Geolocation",
    "AMap.MarkerClusterer"
  ],
  // 默认高德 sdk 版本为 1.4.4
  v: "1.4.4"
});

Vue.use(Element, {
  size: "small"
});
Vue.use(BaiduMap, {
  ak: "5x9pd0zrXUgFco7h5jfzPYuVcL4F2YpH"
});

Vue.filter("dataFormat", function (originVal) {
  const dt = new Date(originVal);

  const y = dt.getFullYear();
  const m = (dt.getMonth() + 1 + "").padStart(2, "0");
  const d = (dt.getDate() + "").padStart(2, "0");

  const hh = (dt.getHours() + "").padStart(2, "0");
  const mm = (dt.getMinutes() + "").padStart(2, "0");
  const ss = (dt.getSeconds() + "").padStart(2, "0");
  // yyyy-mm-dd hh:mm:ss
  return `${y}-${m}-${d} ${hh}:${mm}:${ss}`;
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
