import Vue from "vue";
import VueRouter from "vue-router";
/**
 * 重写路由的push方法
 */
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error);
};

// 路由懒加载
const Authorization = () => import("../components/Authorization.vue");
const Login = () => import("../views/Login.vue");
const Index = () => import("../views/Index.vue");
const Welcome = () => import("../views/auth/index/Welcome.vue");
const RoleMenu = () => import("../views/auth/roleMenu/RoleMenu.vue");
const EquipMagDetail = () =>
  import("../views/equipment/equipMag/EquipMagDetail.vue");
const employeeManagement = () =>
  import("../views/auth/employee/EmployeeManagement.vue");
const employeeDetail = () =>
  import("../views/auth/employee/EmployeeDetail.vue");
const OrganizationManagement = () =>
  import("../views/auth/organization/OrganizationManagement.vue");
const Post = () => import("../views/auth/post/Post.vue");
const Menu = () => import("../views/auth/menu/Menu.vue");
const Authority = () => import("../views/auth/authority/Authority.vue");
const OrganizationTypeMaintenance = () =>
  import("../views/auth/organizationType/OrganizationType.vue");
const myAccountView = () => import("../views/auth/my/MyAccountView.vue");
const SystemConfig = () => import("../views/systemConfig/SystemConfig.vue");
const systemConfigDetails = () =>
  import("../views/systemConfig/SystemConfigDetails.vue");
const CooperationApply = () =>
  import("../views/cooperationApply/CooperationApply.vue");
const CooperationApplyDetails = () =>
  import("../views/cooperationApply/CooperationApplyDetails.vue");
const HelpInfo = () => import("../views/helpInfo/HelpInfo.vue");
const HelpInfoDetails = () => import("../views/helpInfo/HelpInfoDetails.vue");
const paramSetting = () => import("../views/paramSetting/paramSetting.vue");
const paramDetail = () => import("../views/paramSetting/paramDetail.vue");
const RepaManage = () => import("../views/repairs/repaManage/RepaManage.vue");
const RepairsDetail = () => import("../views/repairs/RepairsDetail.vue");
const UnrepaManage = () =>
  import("../views/repairs/unrepaManage/UnrepaManage.vue");
const ManagerM = () => import("../views/managerM/ManagerM.vue");
const ManagerDetails = () => import("../views/managerM/ManagerDetails.vue");
const Member = () => import("../views/member/Member.vue");
const MemberDetails = () => import("../views/member/MemberDetails.vue");
const MemberBill = () => import("../views/memberBill/MemberBill.vue");
const MemberBillDetails = () =>
  import("../views/memberBill/MemberBillDetails.vue");
const MemberVipPayRecords = () =>
  import("../views/memberVipPayRecords/MemberVipPayRecords.vue");
const MemberVipPayRecordsDetails = () =>
  import("../views/memberVipPayRecords/MemberVipPayRecordsDetails.vue");
const OperatorInfo = () =>
  import("../views/operator/operatorInfo/OperatorInfo.vue");
const OperatorAprl = () =>
  import("../views/operator/operatorAprl/OperatorAprl.vue");
const OperatorDetails = () =>
  import("../views/operator/operatorDetails/OperatorDetails.vue");
const OperatorDetail = () =>
  import("../views/operator/operatorDetail/OperatorDetail.vue");
const PayFee = () => import("../views/financeMag/payFee/PayFee.vue");
const PayDetails = () => import("../views/financeMag/details/PayDetails.vue");
const VipPay = () => import("../views/financeMag/vipPay/VipPay.vue");
const VipDetails = () =>
  import("../views/financeMag/vipDetails/VipDetails.vue");
const ProfitBonus = () =>
  import("../views/financeMag/profitBonus/ProfitBonus.vue");
const WithdrawRec = () =>
  import("../views/financeMag/withdrawRec/WithdrawRec.vue");
const WithdrawDetails = () =>
  import("../views/financeMag/withdrawDetails/WithdrawDetails.vue");
const VipInfo = () => import("../views/vipInfo/VipInfo.vue");
const VipInfoDetails = () => import("../views/vipInfo/VipInfoDetails.vue");
const EquipMag = () => import("../views/equipment/equipMag/EquipMag.vue");
const EquipDetails = () =>
  import("../views/equipment/equipDetails/EquipDetails.vue");
const EleFence = () => import("../views/equipment/eleFence/EleFence.vue");
const MapList = () => import("../views/equipment/mapList/MapList.vue");
const CarManager = () => import("../views/equipment/carManager/CarManager.vue");
const ApplyFee = () => import("../views/financeMag/applyFee/ApplyFee.vue");
const ApplyFeeDetail = () =>
  import("../views/financeMag/applyFee/ApplyFeeDetail.vue");
const DataManege = () => import("../views/dataManage/DataManage.vue");
const VipList = () => import("../views/vipList/VipList.vue");
const DataManageMonth = () => import("../views/dataManage/DataManageMonth.vue");
const CarManagerDetails = () =>
  import("../views/equipment/carManager/CarManagerDetails.vue");
const Swiper = () => import("../views/swiper/Swiper.vue");
const SwiperDetail = () => import("../views/swiper/SwiperDetail.vue"); //轮播图详情

const BillingModel = () => import("../views/billingModel/index"); //计费模式
const ChargeManagement = () => import("../views/chargeManagement/index"); //收费管理
const CompensationManage = () => import("../views/compensationManage/index"); // 赔偿管理
const MemberOrderList = () => import("../views/member/MemberOrderList.vue"); //用户订单列表
const EleFenceType = () =>
  import("../views/equipment/eleFenceType/EleFenceType.vue"); //电子围栏类型
const AboutUs = () => import("../views/systemSettings/aboutUs/index"); //关于我们
const CompanyIntroduction = () =>
  import("../views/systemSettings/companyIntroduction/index"); //公司介绍

const Feedback = () => import("../views/feedback/Feedback.vue"); //意见反馈
const FeedbackDetails = () => import("../views/feedback/FeedbackDetails.vue");
const MyEleFence = () => import("../views/equipment/myEleFence/myEleFence.vue");
const MyEauipMag = () => import("../views/equipment/myEquipMag/myEquipMag.vue");
const MyProfitBonus = () =>
  import("../views/financeMag/myProfitBonus/myProfitBonus.vue");
const PlatformRevenue = () =>
  import("../views/financeMag/platformRevenue/platformRevenue.vue");
Vue.use(VueRouter);
const MemberGuarantee = () => import('../views/memberGuarantee/MemberGuarantee.vue')

const routes = [
  { path: "/", redirect: "/financeMag/profitBonus" },
  { path: "/authorization", component: Authorization },
  { path: "/login", component: Login },
  {
    path: "/index",
    component: Index,
    redirect: "/Welcome",
    children: [
      { path: "/index", component: Welcome },
      { path: "/employee", component: employeeManagement },
      { path: "/employee/details", component: employeeDetail },
      { path: "/organization", component: OrganizationManagement },
      { path: "/post", component: Post },
      { path: "/menu", component: Menu },
      { path: "/authority", component: Authority },
      { path: "/organizationType", component: OrganizationTypeMaintenance },
      { path: "/myAccountView", component: myAccountView },
      { path: "/roleMenu", component: RoleMenu },
      { path: "/systemConfig", component: SystemConfig },
      { path: "/systemConfig/details", component: systemConfigDetails },
      { path: "/cooperationApply", component: CooperationApply },
      { path: "/cooperationApply/details", component: CooperationApplyDetails },
      { path: "/helpInfo", component: HelpInfo },
      { path: "/paramSetting", component: paramSetting },
      { path: "/paramSetting/detail", component: paramDetail },
      { path: "/repairs/repaManage", component: RepaManage },
      { path: "/repairs/detail", component: RepairsDetail },
      { path: "/repairs/unrepaManage", component: UnrepaManage },
      { path: "/helpInfo/details", component: HelpInfoDetails },
      { path: "/managerM", component: ManagerM },
      { path: "/managerM/details", component: ManagerDetails },
      { path: "/member", component: Member },
      { path: "/member/details", component: MemberDetails },
      { path: "/memberBill", component: MemberBill },
      { path: "/memberBill/details", component: MemberBillDetails },
      { path: "/memberVipPayRecords", component: MemberVipPayRecords },
      {
        path: "/memberVipPayRecords/details",
        component: MemberVipPayRecordsDetails
      },
      { path: "/operator/info", component: OperatorInfo },
      { path: "/operator/aprl", component: OperatorAprl },
      { path: "/operator/details", component: OperatorDetails },
      { path: "/operator/detail", component: OperatorDetail },
      { path: "/financeMag/payFee", component: PayFee },
      { path: "/financeMag/details", component: PayDetails },
      { path: "/financeMag/vipPay", component: VipPay },
      { path: "/financeMag/vipDetails", component: VipDetails },
      { path: "/financeMag/profitBonus", component: ProfitBonus },
      { path: "/financeMag/withdrawRec", component: WithdrawRec },
      { path: "/financeMag/withdrawDetails", component: WithdrawDetails },
      { path: "/vipInfo", component: VipInfo },
      { path: "/vipInfo/details", component: VipInfoDetails },
      { path: "/equipment/equipMag", component: EquipMag },
      { path: "/equipment/equipMagDetail", component: EquipMagDetail },
      { path: "/equipment/equipDetails", component: EquipDetails },
      { path: "/equipment/eleFence", component: EleFence },
      { path: "/equipment/mapList", component: MapList },
      { path: "/carManager", component: CarManager },
      { path: "/carManager/details", component: CarManagerDetails },
      { path: "/financeMag/applyFeeDetail", component: ApplyFeeDetail },
      { path: "/financeMag/applyFee", component: ApplyFee },
      { path: "/dataManege/dataManege", component: DataManege },
      { path: "/viplist/Viplist", component: VipList },
      { path: "/dataManageMonth", component: DataManageMonth },
      { path: "/swiper", component: Swiper },
      { path: "/swiperDetail", component: SwiperDetail },
      { path: "/billingModel", component: BillingModel },
      { path: "/chargeManagement", component: ChargeManagement },
      { path: "/compensationManage", component: CompensationManage },
      { path: "/memberOrderList", component: MemberOrderList },
      { path: "/eleFenceType", component: EleFenceType },
      { path: "/aboutUs", component: AboutUs },
      { path: "/companyIntroduction", component: CompanyIntroduction },
      { path: "/feedback", component: Feedback },
      { path: "/feedback/details", component: FeedbackDetails },
      { path: "/equipment/myEleFence", component: MyEleFence },
      { path: "/equipment/myEquipMag", component: MyEauipMag },
      { path: "/financeMag/myProfitBonus", component: MyProfitBonus },
      { path: "/financeMag/platformRevenue", component: PlatformRevenue },
      { path: '/memberGuarantee', component: MemberGuarantee },
    ]
  }
];

const router = new VueRouter({
  routes
});

// 挂载路由导航守卫,to表示将要访问的路径，from表示从哪里来，next是下一个要做的操作 next('/login')强制跳转login
router.beforeEach((to, from, next) => {
  console.log(to, "前往的路径");
  if (to.path === "/login" || to.path === "/authorization") {
    next();
  } else {
    let token = ""; //token保存在localstorage中

    let item = localStorage.getItem("token");
    // 先将拿到的试着进行json转为对象的形式
    try {
      item = JSON.parse(item);
    } catch (error) {
      // eslint-disable-next-line no-self-assign
      item = item;
    }
    if (item && item.startTime) {
      let date = new Date().getTime();
      // 如果大于就是过期了，如果小于或等于就还没过期
      let timel = date - item.startTime;
      console.log("timelength:" + timel + ",expires:" + item.expires);
      if (timel < item.expires) {
        token = item.value;
      }
      next();
    }
    if (token === null || token === "") {
      next("/login");
    } else {
      next();
    }
  }
});

export default router;
